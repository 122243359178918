import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'

const Indent1 = styled.div`
  padding: 0 0 32px 20px;
`

const Indent2 = styled.div`
  padding: 0 0 32px 32px;
`

const Widerrufsbelehrung = () => {
  return (
    <GlobalWrapper>
      <Seo title="AGB" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Widerrufsbelehrung</h1>
            <h2>Widerrufsrecht</h2>
            <p>
              Verbrauchern steht ein Widerrufsrecht zu, wenn der Vertrag
              außerhalb von Geschäftsräumen abgeschlossen worden ist, zum
              Beispiel Online.
            </p>
            <p>
              Verbraucher ist gemäß § 13 BGB jede natürliche Person, die ein
              Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer
              gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
              zugerechnet werden können.
            </p>
            <h2>I. Widerrufsbelehrung für Verbraucher</h2>
            <Indent1>
              <p>
                <b>Widerrufsrecht</b>
              </p>
              <p>
                Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen
                diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14Tage
                ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht
                auszuüben, müssen Sie uns
              </p>
              <p>
                Hytide GmbH
                <br />
                Türkenstr. 5b<br />
                85748 Garching
              </p>

              <p>
                ​Telefon: +49 (0) 1573 7654 21 und eine 0
                <br />
                E-Mail: info[ät]stretchzeltbayern.de
              </p>

              <p>
                mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                versandter Brief oder E-Mail) über Ihren Entschluss, diesen
                Vertrag zu widerrufen, informieren. Sie können dafür das
                beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
                vorgeschrieben ist.
              </p>
              <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                Widerrufsfrist absenden.
              </p>
              <p>
                <b>Folgen des Widerrufs</b>
              </p>
              <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
                daraus ergeben, dass Sie eine andere Art der Lieferung als die
                von uns angebotene, günstigste Standardlieferung gewählt haben),
                unverzüglich und spätestens vierzehn Tagen ab dem Tag
                zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses
                Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                verwenden wir dasselbe Zahlungsmittel, das Sie bei der
                ursprünglichen Transaktion eingesetzt haben, es sei denn, mit
                Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem
                Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.{' '}
              </p>
              <p>
                Haben Sie verlangt, dass die Dienstleistungen während der
                Widerrufsfrist beginnen soll, so haben Sie uns einen
                angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
                Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts
                hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
                Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
                vorgesehenen Dienstleistungen entspricht.
              </p>
            </Indent1>
            <h2>II. Hinweis zum vorzeitigen Erlöschen des Widerrufsrechts</h2>
            <Indent1>
              <p>
                Ihr Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von
                Dienstleistungen vorzeitig, wenn wir die Dienstleistung
                vollständig erbracht haben und mit der Ausführung der
                Dienstleistung erst begonnen haben, nachdem Sie dazu Ihre
                ausdrückliche Zustimmung gegeben haben und gleichzeitig Ihre
                Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei
                vollständiger Vertragserfüllung durch uns verlieren.
              </p>
              <p>
                Zustimmen können Sie{' '}
                <Link to="/zustimmungserklaerung">hier</Link>
              </p>
            </Indent1>
            <h2>III. Muster Widerrufsformular</h2>
            <Indent1>
              <p>
                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
                dieses Formular aus und senden Sie es zurück:
              </p>
              <hr />
              <p>
                Hytide GmbH
                <br />
                Türkenstr. 5b<br />
                85748 Garching
              </p>
              <p>
                Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*)
                abgeschlossenen Vertrag über die Miete der folgenden Gegenstände
                (*)/ die Erbringung der folgenden Dienstleistung (*)
                <ul>
                  <li>Bestellt am (*)/ erhalten am (*)</li>
                  <li>Name des/ der Verbraucher(s)</li>
                  <li>Anschrift des/ der Verbraucher(s)</li>
                  <li>
                    Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf
                    Papier)
                  </li>
                  <li>Datum</li>
                </ul>
              </p>
              <hr />
              <p>(*) Unzutreffendes streichen.</p>
            </Indent1>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Widerrufsbelehrung
